<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Xoá tài khoản</h4>
        <p class="f14 fw600 mb-4">
          Nhập mật khẩu của bạn để xác nhận xoá tài khoản
        </p>
        <div class="content-inner__body-form">
          <ValidationObserver ref="form">
            <form @keyup.enter="handleDeleteAccount">
              <ValidationProvider
                name="Mật khẩu"
                vid="password"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    v-model="form.password"
                    type="password"
                    class="form-control"
                    id="floatingUsername"
                    placeholder="name@example.com"
                  />
                  <label for="floatingUsername">Mật khẩu</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
        <button
          type="button"
          class="btn btn-danger mb-4"
          @click="handleDeleteAccount"
        >
          XÁC NHẬN
        </button>
      </div>
      <Footer />
    </div>

    <b-modal
      v-model="showModalConfirm"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <h5 class="fw600">Xác nhận xoá tài khoản</h5>
        <span class="f14 text-second-color">
          Bạn có chắc chắn muốn xoá tài khoản Next360?
        </span>
      </div>
      <b-button
        variant="danger"
        class="mt-3 mb-2 w-100"
        @click="handleConfirmDelete"
      >
        Xác nhận
      </b-button>
      <b-button variant="light" class="w-100" @click="handleCancelDelete">
        Tôi nghĩ lại
      </b-button>
    </b-modal>

    <b-modal
      v-model="showModalSuccess"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <h5 class="fw600">Xoá tài khoản thành công</h5>
        <span class="f14 text-second-color">
          Tài khoản Next360 của bạn đã được xoá thành công
        </span>
      </div>
      <b-button variant="primary" class="mt-3 w-100" @click="handleConfirm">
        Xác nhận
      </b-button>
    </b-modal>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      token: this.$cookies.get("token"),
      loading: {
        isLoading: false,
        fullPage: true,
      },
      form: {},
      showModalConfirm: false,
      showModalSuccess: false,
    };
  },
  methods: {
    handleDeleteAccount() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            const response = await UserService.verifyPassword(
              this.token,
              this.form
            );
            if (response.data.valid) {
              this.showModalConfirm = true;
            } else {
              this.$refs.form.setErrors({
                password: "Mật khẩu không đúng",
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
    async handleConfirmDelete() {
      try {
        const response = await UserService.deleteUser(this.token);
        if (response.code === "SUCCESS") {
          this.showModalConfirm = false;
          this.showModalSuccess = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCancelDelete() {
      this.showModalConfirm = false;
    },
    handleConfirm() {
      const { accountRedirectUri } = this.$route.query;
      if (accountRedirectUri) {
        window.location.href = `${accountRedirectUri}?success=true`;
        this.$router.push("/logout");
      } else {
        this.$router.push("/logout");
        window.location.reload();
      }
    },
  },
};
</script>
